




















import { Component, Vue } from "vue-property-decorator";
import { readUserProfile } from "@/store/main/getters";

@Component
export default class Dashboard extends Vue {
  get greetedUser() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      if (userProfile.full_name) {
        return userProfile.full_name;
      } else {
        return userProfile.email;
      }
    }

    return "Benutzername";
  }
}
